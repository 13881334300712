import React from "react";
import SingleQuestion from "./question";
import { data } from "./data";
import "./faqs.css";

const FQAs = () => {
  const questions = data;

  return (
    <div className="FAQ section__padding" id="FAQs">
      <div className="FAQ-heading gradient__text">FAQs</div>

      <div className="FAQ-questions">
        {questions.map((question) => {
          return (
            <SingleQuestion
              className="FAQ-question-items"
              question={question}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FQAs;
