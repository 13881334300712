import React, { useState } from "react";
import { Footer } from "./sections";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  AboutUs,
  LoginPage,
  ActivatePage,
  NeuroEvaluation,
  NotFound,
  Pricing,
  RegisterPage,
  HomePage,
  DoctorPage,
  ServicesPage,
  FAQsPage,
  TeamPage,
  ReserveSeats,
} from "./pages/index";
import { CTA } from "./components";

function App() {
  const [color, changeColor] = useState("#01050d");
  const isBlogPageRendering = false;

  return (
    <div style={{ background: color }}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/evaluation" element={<NeuroEvaluation />} />
          <Route path="/activate/:uid/:token" element={<ActivatePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/doctors" element={<DoctorPage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/rsvp" element={<ReserveSeats />} />
          <Route
            exact={true}
            path="/team/:doctorName"
            element={<DoctorPage />}
          />
          <Route path="/specialty" element={<ServicesPage />} />
          <Route path="/FAQs" element={<FAQsPage />} />
        </Routes>
        {!isBlogPageRendering && <Footer />}
      </Router>
    </div>
  );
}

export default App;
